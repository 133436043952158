export const environment = {
	production: false,
	requestTimeout: 180000,
	dwsApiBaseUrl: 'https://dwsdev.setportal.set.or.th/api/',
	LoginUrl: 'https://www.scpdev.set/setdd',
	digitalSignatureBaseUrl: 'https://web-example-secsignv4-api-iwt.sec.or.th',
	SEC_API: 'https://web-dw-api-iwt.sec.or.th/api/',
	SET_URL: 'https://www.scpdev.set/',
	DW_QUOTA_FLAG: true,
	digitalSignShortTimeout: 600000,
	digitalSignFullTimeout: 600000,
	isosApiBaseUrl: 'https://iosdev.setportal.set.or.th'
}
